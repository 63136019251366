.investment__memo--document-nav {
    display: flex;
    z-index: 3;
    width: 235px;
    
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 580px;
    margin-top: 15px;

    .document-nav-item {
        letter-spacing: 0.5px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: #141414;
        cursor: pointer;

        &:hover {
            color: #04a1df;
        }
    }

    .document-nav-container {
        padding: 10px 10px 15px 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        width: 200px;
        box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.3);
        background: #ffffff;
        border-radius: 5px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .document-nav-section-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;

        .document-nav-block-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            margin-left: 24px;
            height: 16px;

            .document-nav-item-icon {
                font-size: 12px;
                color: #c41e3a;
            }
        }
    }
}