
.chat-container {
    display: flex;
    flex-direction: column;
    width: 320px;
    padding-top: 5px;

    p {
        margin: 6px 0;
    }
}

.messages {
    max-height: 55vh;
    overflow: auto;
    padding-top: 10px;
    border-top: 1px solid #f1f2f2;
}

.avatar {
    display: flex;
    align-items: start;
    justify-content: center;
    z-index: 3;
}

.message.fixed:first-child {
    margin-left: -18px;
}

.message {
    display: flex;
    flex-direction: row;
    padding: 10px 0 5px 0;
}

.message.fixed {
    display: flex;
    flex-direction: row;
    padding: 10px 0 5px 0;
}

.message-details {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 10px;
}

.message-name {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.4px;
}

.message-content {
    font-size: 13px;
    font-family: "lato", sans-serif;
    text-align: start;
    line-height: 16px;
}

.message-time {
    font-size: 10px;
}

.message-input {
    padding: 10px;
}