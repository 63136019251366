.App {
  display: flex;
  text-align: center;
}

.App-body {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  color: #000;
  max-height: 0px;

  .App-content-header {
    padding-top: 90px;
  }

  .App-content-stepper {
    padding: 130px 0px 10px 0px;
  }

  .App-content-investment-memo {
    min-height: 0px;
    display: flex;
    flex: 1;
    padding: 0px;
    margin: 0px;
  }
}

input {
  font-family: 'lato' !important;
}

.MuiInputLabel-root {
  margin-top: -20px;
  font-weight: 700!important;
  color: #000!important;
  font-family: 'lato'!important;
}

.MuiFormControl-root {
  margin-top: 20px!important;
}

.MuiTextField-root {
  margin-top: 0!important;
}

.MuiTypography-root {
  font-family: 'lato'!important;
  font-size: 13px;
  font-weight: 600;
}

.MuiButton-root {
  font-family: 'lato'!important;
  text-transform: none!important;
  font-weight: 700!important;
}

.MuiStepLabel-label {
  font-family: 'lato' !important;
  font-weight: 700 !important;
}

.MuiStepLabel-label.Mui-error {
  color: #24da78 !important;
}

textarea {
  font-family: 'lato' !important;
}

textarea:focus-visible {
  outline: none !important;
}

.MuiPaper-root:focus-visible {
  outline: none !important;
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #D6D6D6;
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  margin: 0;
  display: flex;
  justify-content: center;
}

.MuiTab-root {
  text-transform: math-auto!important;
}

.MuiStepLabel-label {
  cursor: pointer;
}